@font-face {
  font-display: block;
  font-family: 'rc2-icon-appendix';
  font-style: normal;
  font-weight: normal;
  src: url('../iconfont/rc2-icon-appendix.ttf?ii7b2q') format('truetype'),
    url('../iconfont/rc2-icon-appendix.woff?ii7b2q') format('woff'),
    url('../iconfont/rc2-icon-appendix.svg?ii7b2q#rc2-icon-appendix') format('svg');
}

[class^='icon-'],
[class*=' icon-'] {
  padding: 4px;

  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'rc2-icon-appendix' !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

.icon-i01-arrow-1-down:before {
  content: '\e902';
}
.icon-i02-arrow-1-up:before {
  content: '\e903';
}
.icon-i03-arrow-1-left:before {
  content: '\e904';
}
.icon-i04-arrow-1-right:before {
  content: '\e905';
}
.icon-i05-arrow-2-down:before {
  content: '\e906';
}
.icon-i06-arrow-2-up:before {
  content: '\e907';
}
.icon-i07-arrow-2-left:before {
  content: '\e908';
}
.icon-i08-arrow-2-right:before {
  content: '\e909';
}
.icon-i09-sort-down-active:before {
  content: '\e90a';
}
.icon-i10-sort-up-active:before {
  content: '\e90b';
}
.icon-i11-sort-down-inactive:before {
  content: '\e90c';
}
.icon-i12-sort-up-inactive:before {
  content: '\e90d';
}
.icon-i13-user:before {
  content: '\e90e';
}
.icon-i14-hide:before {
  content: '\e90f';
}
.icon-i15-filter:before {
  content: '\e910';
}
.icon-i16-state-draft:before {
  content: '\e911';
}
.icon-i17-state-verify:before {
  content: '\e912';
}
.icon-i18-state-check:before {
  content: '\e913';
}
.icon-i19-help-label-form:before {
  content: '\e914';
}
.icon-i20-logo-recall:before {
  content: '\e915';
}
.icon-i21-help:before {
  content: '\e916';
}
.icon-i22-add-1:before {
  content: '\e917';
}
.icon-i23-add-2:before {
  content: '\e918';
}
.icon-i24-add-3:before {
  content: '\e919';
}
.icon-i25-add-attachment:before {
  content: '\e91a';
}
.icon-i26-document:before {
  content: '\e91b';
}
.icon-i27-download:before {
  content: '\e91c';
}
.icon-i28-state-change:before {
  content: '\e91d';
}
.icon-i29-accept:before {
  content: '\e91e';
}
.icon-i30-forward:before {
  content: '\e91f';
}
.icon-i31-close-window:before {
  content: '\e920';
}
.icon-i32-remove-item:before {
  content: '\e921';
}
.icon-i33-warning:before {
  content: '\e922';
}
.icon-i34-logo-vw:before {
  content: '\e923';
}
.icon-i35-logo-audi:before {
  content: '\e924';
}
.icon-i36-logo-seat:before {
  content: '\e925';
}
.icon-i37-logo-skoda:before {
  content: '\e926';
}
.icon-i49-state-check-outline:before {
  content: '\e927';
}
.icon-i39-logo-man:before {
  content: '\e928';
}
.icon-i40-logo-porsche:before {
  content: '\e929';
}
.icon-i42-status:before {
  content: '\e92a';
}
.icon-i43-status-2:before {
  content: '\e92b';
}
.icon-i44-link:before {
  content: '\e92c';
}
.icon-i45-drag-vertical:before {
  content: '\e92d';
}
.icon-i46-mail-summary:before {
  content: '\e92e';
}
.icon-i47-enter:before {
  content: '\e92f';
}
.icon-i48-gremium:before {
  content: '\e930';
}
.icon-i38-logo-bentley:before {
  content: '\e931';
}
.icon-i50-alarm:before {
  content: '\e932';
}
.icon-i51-alert:before {
  content: '\e933';
}
.icon-i52-car:before {
  content: '\e934';
}
.icon-i53-crash:before {
  content: '\e935';
}
.icon-i54-comment:before {
  content: '\e936';
}
.icon-i55-clipboard:before {
  content: '\e937';
}
.icon-i56-delete:before {
  content: '\e938';
}
.icon-i57-edit:before {
  content: '\e939';
}
.icon-i58-grid-view:before {
  content: '\e93a';
}
.icon-i59-list-view:before {
  content: '\e93b';
}
.icon-i60-home:before {
  content: '\e93c';
}
.icon-i61-menu:before {
  content: '\e93d';
}
.icon-i62-incorrect:before {
  content: '\e93e';
}
.icon-i63-info:before {
  content: '\e93f';
}
.icon-i64-more:before {
  content: '\e940';
}
.icon-i65-search:before {
  content: '\e941';
}
.icon-i66-save:before {
  content: '\e942';
}
.icon-i67-reload:before {
  content: '\e943';
}
.icon-i68-settings:before {
  content: '\e944';
}
.icon-i69-attachment:before {
  content: '\e945';
}
.icon-i70-objekt-agendapunkt:before {
  content: '\e946';
}
.icon-i71-objekt-aktion:before {
  content: '\e947';
}
.icon-i72-objekt-clearing:before {
  content: '\e948';
}
.icon-i73-objekt-kriterium:before {
  content: '\e949';
}
.icon-i74-objekt-meldung:before {
  content: '\e94a';
}
.icon-i75-objekt-protokoll:before {
  content: '\e94b';
}
.icon-i76-objekt-pruefung:before {
  content: '\e94c';
}
.icon-i77-objekt-sitzung:before {
  content: '\e94d';
}
.icon-i78-objekt-teilebestellung:before {
  content: '\e94e';
}
.icon-i79-objekt-thema:before {
  content: '\e94f';
}
.icon-i80-objekt-benutzer:before {
  content: '\e950';
}
.icon-i81-objekt-benutzerantrag:before {
  content: '\e951';
}
.icon-i82-objekt-gremium:before {
  content: '\e952';
}
.icon-i83-double-arrows:before {
  content: '\e953';
}
.icon-i84-unlink:before {
  content: '\e954';
}
.icon-i85-urgency-1:before {
  content: '\e955';
}
.icon-i86-urgency-2:before {
  content: '\e956';
}
.icon-i87-urgency-3:before {
  content: '\e957';
}
.icon-i88-urgency-4:before {
  content: '\e958';
}
.icon-i89-download-cloud:before {
  content: '\e959';
}
.icon-i92-part-select:before {
  content: '\e95a';
}
.icon-i90-unselect:before {
  content: '\e95b';
}
.icon-i91-select:before {
  content: '\e95c';
}
.icon-i93-exclamation-mark:before {
  content: '\e95d';
}
.icon-i94-substract:before {
  content: '\e95e';
}
.icon-i95-open-tab:before {
  content: '\e95f';
}
.icon-i96-arrow-slideout:before {
  content: '\e960';
}
.icon-i97-calendar:before {
  content: '\e961';
}
.icon-i98-time:before {
  content: '\e962';
}
.icon-i99-close:before {
  content: '\e963';
}
.icon-i100-enter-small:before {
  content: '\e964';
}
.icon-i101-play:before {
  content: '\e965';
}
.icon-i102-return:before {
  content: '\e966';
}
.icon-i104-state-attention:before {
  content: '\e967';
}
.icon-i103-state-summary:before {
  content: '\e968';
}
.icon-i105-upload:before {
  content: '\e969';
}
.icon-i106-topic-urgency-1:before {
  content: '\e96a';
}
.icon-i107-topic-urgency-2:before {
  content: '\e96b';
}
.icon-i108-topic-urgency-3:before {
  content: '\e96c';
}
.icon-i109-topic-urgency-4:before {
  content: '\e96d';
}
.icon-i110-forward-small:before {
  content: '\e96e';
}
.icon-i111-pause:before {
  content: '\e96f';
}
.icon-i112-logo-bugatti:before {
  content: '\e970';
}
.icon-i113-logo-lamborghini:before {
  content: '\e971';
}
.icon-i115-logo-scania:before {
  content: '\e972';
}
.icon-i114-logo-ducati:before {
  content: '\e973';
}
.icon-i116-invalid:before {
  content: '\e974';
}
.icon-i117-move:before {
  content: '\e975';
}
.icon-i118-copy:before {
  content: '\e976';
}
.icon-i119-logo-ford:before {
  content: "\e977";
}
.icon-operation-filter-small:before {
  content: '\e900';
}
.icon-operation-filter-filled-small:before {
  content: '\e901';
}
